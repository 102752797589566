<script>
import $ from "jquery"
import _ from "lodash"
import GlobalVue from '@libs/Global.vue';
import Gen from '@libs/Gen';
require('@fancyapps/fancybox')
require('@fancyapps/fancybox/dist/jquery.fancybox.css')

export default {
	extends: GlobalVue,
  props: {
    type: String,
    value: String,
    label: String,
    uploadType: {default: ""},
    attr:{default:()=>({})},
    param: {default:()=>({thumbnail:false,edit:false})},
    galleryParams: {default:()=>({})},
    config: Object,
    noHelp: Boolean,
    noLoading: {type:Boolean,default:()=>(true)},
    noPreview: Boolean,
    noPreviewFile: {
      type: Boolean,
      default: true
    },
    block: Boolean,
    btnClass: String, 
    readonly: Boolean, 
    squarePreview: {default: false},
    removeAction: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      uploading: false,
      error: false,
      inputFile: null,
      imgFile: String,
    }
  },
  computed:{
    imagePreviewClass() { 
      let str = 'image-preview'

      if (this.squarePreview) {
        str += ' w-100'
      }

      return str
    },
    fields(){ return this.$root.fields },
    conf(){ return _.assign(_.clone(this.$root.app.uploader[this.type]),this.config||{}) },
    base(){ return this.$root.apiUrl },
    accept(){
      return "."+(this.conf.rule_type || '').split(",").join(",.")
    },
    uploadText(){
      if(this.value) return "Update"
      return this.uploadType == "gallery" ? "Select" : "Upload"
    }
  },
  mounted(){
    this.init()
    this.inputFile = $("<input type='file' accept='"+this.accept+"'>")[0]
    this.inputFile.onclick = ()=>{ this.inputFile.value = '' }
    this.inputFile.onchange = this.onUpload
    global.inputFile = this.inputFile
    
    if(this.$slots.btn) this.$slots.btn[0].elm.onclick = this.uploadClick
  },
  methods: {
    init(){
      if(!this.conf) return
      this.param.type = this.type
      if (!this.uploadType){
        this.param.uploadType = this.conf.img_ratio ? "cropping" : "upload"
      }else{
        this.param.uploadType = _.clone(this.uploadType)
      }
    },
    uploadClick(){
      if(this.uploadType=='gallery') return this.openGallery()
      return this.inputFile.click()
    },
    openGallery(){
      global.FileManager.open((files)=>{
        if(files.length){
          var arrayValue = [];
          files.forEach((v)=>{
            arrayValue.push(v.pathfile)
          })
          this.$emit('input', arrayValue)
        }else{
          this.$emit('input', files.pathfile);
        }
        this.$emit('response', this)
      }, _.assign({type:this.type}, this.galleryParams))
    },
    removeUpload(){
      this.$emit('input', "");
      this.$emit('data', "");
    },
    onUpload(e){
      this.fileData = $(this.inputFile).prop('files')[0];
      this.conf.fileType = this.fileData.type;
      this.fileType = this.fileData.name.substr(this.fileData.name.lastIndexOf('.') + 1).toLowerCase();
      if (this.conf.rule_size){
        var reader = new FileReader();
        reader.onload = () => {
          var img = new Image;
          img.onload = () => {
            this.img = img
            this.uploadProcess(e)
          }
          img.src = reader.result
        };
        reader.readAsDataURL(this.fileData);
      }else{
        this.uploadProcess(e)
      }
    },
    uploadProcess(){
      // Validation
      this.error = false;
      if (this.conf['rule_type'].indexOf(this.fileType) == -1) {
        this.error = 'File type must be ' + this.conf['rule_type'] + ' type.';
      }
      if (this.type === 'documents' && this.fileData.size > this.$root.app.uploader['max_file_size']) {
        this.error = 'Max file size is '+this.$root.app.uploader['max_file_size'].bytesToSize();
      } else if (this.type === 'file_race_guide' && this.fileData.size > this.$root.app.uploader['max_file_size']) {
        this.error = 'Max file size is '+this.$root.app.uploader['max_file_size'].bytesToSize();
      } else if (this.type === 'video' && this.fileData.size > this.$root.app.uploader['max_video_size']) {
        this.error = 'Max file size is '+this.$root.app.uploader['max_video_size'].bytesToSize();
      } else if (['documents', 'video','file_race_guide'].indexOf(this.type) === -1 && this.fileData.size > this.$root.app.uploader['max_image_size']) {
        this.error = 'Max file size is '+this.$root.app.uploader['max_image_size'].bytesToSize();
      }
      if (this.conf.rule_size) if(this.img.naturalWidth<this.conf.rule_size[0]||this.img.naturalHeight<this.conf.rule_size[1]){
        this.error = 'Minimum image size is '+this.conf.rule_size[0]+"x"+this.conf.rule_size[1]
      }
      if (this.error){
        return alert(this.error);
      }

      // Automatic upload if not image
      if(("jpg,jpeg,png").indexOf(this.fileType) < 0) this.param.uploadType = "upload";

      // Quick Image Upload Filter
      if(this.param.uploadType != "upload"){
        let reader = new FileReader()
        reader.onload = (e)=>{
          this.imageFilter(e.target.result, this.fileData.name)
        }
        reader.readAsDataURL(this.fileData)
        return 
      }

      let formData = new FormData();
      formData.append(this.type=="editor" ? 'upload' : 'file', this.fileData);
      this.param.pageType = this.type;
      $.each(this.param, (k, v) => {
        formData.append(k, v);
      });
      let query = {
        token: Gen.getCookie("bo_auth"),
        pageType: this.type
      }
      this.uploading = true
      $.ajax({
        url: this.apiUrl + "/api/app/ajax/upload?"+Gen.objectToQuery(query),
        headers:Gen.apiHeader(),
        type: "POST",
        data: formData,
        enctype: 'multipart/form-data',
        processData: false, // tell jQuery not to process the data
        contentType: false, // tell jQuery not to set contentType
        xhr: ()=>{
          let xhr = new window.XMLHttpRequest();
          let ajax_progress = $('<p style="margin-bottom:0px;" class="label_progress"></p><div class="progress" style="margin:0px 0px 0px;height:20px;"><div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" style="width:3%"></div></div>');
          $(this.$el).find(".ajax_progress").html(ajax_progress);
          //Upload progress
          xhr.upload.addEventListener("progress", (evt)=>{
            if (evt.lengthComputable) {
              let percentComplete = evt.loaded / evt.total;
              let label_process = "File Upload: "+(evt.loaded/1000)+  "Kb / " + (evt.total/1000)+"Kb.";
              // Do something with upload progress
              ajax_progress.find('.progress-bar').width((percentComplete*100)+"%");
              $('.label_progress').text(label_process);
              if(percentComplete == 1){
                setTimeout(()=>{ ajax_progress.fadeOut(500); }, 600);
                setTimeout(()=>{ $(this.$el).find(".ajax_progress").html(""); }, 1100);
              }
            }
          }, false);
          return xhr;
        }
      }).done((resp) => {
        this.uploading = false
        if (this.param.uploadType == "upload" && ['video', 'documents'].indexOf(this.type) === -1) {
          console.log('resp', resp)
          this.$emit('input', resp.fallback_pathfile);
          this.$emit('data', resp.pathfile);
          this.$emit('resp', resp);
          this.$emit('response', this);
          return;
        } else {
          // this.$emit('input', resp.pathfile);
          // this.$emit('resp', resp);
          // this.$emit('response', this);
          
          return this.imageFilter(resp.targetfile, resp.filename);
        }
      }).fail((err) => {
        if (err.status == 422) {
          alert((err.responseJSON || {}).message);
        }
        this.uploading = false;
      });
    },
    imageFilter(img, filename){
      let query = {
        token: Gen.getCookie("bo_auth"),
      }
      global.ImageFilter.open(img, (data) => {
        let form_data = new FormData();
        form_data.append("file", data);
        form_data.append("path", this.type);
        form_data.append("image_name", filename);
        this.$emit("beforeDone", this)
        try {
          $.ajax({
            url: this.apiUrl + "/api/app/ajax/upload_filter?"+Gen.objectToQuery(query),
            headers:Gen.apiHeader(),
            type: "POST",
            data: form_data,
            enctype: 'multipart/form-data',
            processData: false,  // tell jQuery not to process the data
            contentType: false,
            success: (resp) => {
              console.log('input', (resp.pathfile || {}).fallback);
              this.$emit('input', (resp.pathfile || {}).fallback);
              this.$emit('response', this);
              this.$emit('data', (resp.pathfile || {}).main);
              this.imgFile=resp.targetfile;
              if($("div").hasClass("avatar_prof")){
                $('.avatar_prof').html('<img src="'+resp.targetfile+'" alt="user">');
              }
            }
          });
        } catch (error) {
          console.log(error);
        }
      }, this.conf);
    },
  },
  watch:{
    type(){
      setTimeout(()=>{ this.init() },300)
    },
    '$root.app.uploader'(){
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-container{
  position: relative;
  .image-preview{
    position: relative;
    .remove{
      position: absolute;
      display: block;
      background: #ff6767;
      width: 18px;
      height: 18px;
      color: #fff;
      padding: 0px 5px;
      line-height: 15px;
      border-radius: 50%;
      top: -5px;
      right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .edit{
      position: absolute;
      display: block;
      background: #fff;
      width: 21px;
      height: 21px;
      color: #333;
      // padding: 0px 13px;
      border: 1px solid #333;
      line-height: 15px;
      border-radius: 50%;
      top: -8px;
      right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.ajax_progress{
  height: 20px;
  right: 0;
  width: 40%;
  top: 0;
  display: inline-grid;
  position: absolute;
}
.upload-btn{
  position: relative;
  input[type=file]{
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
.upload_wrapper{
  vertical-align: middle;
}
.upload_wrapper.upload_w100,.upload_wrapper.upload_w100 .file-upload-box{
  display:block;
}

.filePreview{
  border: 2px dashed #95378E;
  padding: 1rem;
  border-bottom: 0 !important;
  text-align: center;
  width: 100%;
}
</style>

<template>
  <div class="upload-container" v-if="conf">
    <slot name="preview" v-if="!noPreview">
      <div :class="imagePreviewClass" v-show="value" class="bg-grey">
        <div v-if="!param.thumbnail">
          <ImagePreview class="mr10" :src="uploader(value)" v-if="value"></ImagePreview>
          <!-- <img v-if="value" :src="uploader(value)" class="w-100"> -->
          <a href="javascript:;" @click="removeUpload" class="remove" v-if="removeAction && value && !readonly">x</a>
        </div>
        <a href="javascript:;" @click="$emit('editProof')" class="edit" v-if="param.edit"><i class="fas fa-pencil-alt" /></a>
        <!-- <div v-else>
          <VImg class="image_thumb" v-for="(v,k) in value" :key="k" :src="uploader(v)"></VImg>
        </div> -->
        <div
          v-else-if="param.thumbnail && !param.video"
          class="img-square-preview"
          :style="'background-image:url(' + uploader(value) + ');'"
        >
          <a :href="uploader(value)" data-fancybox class="img-square-preview__overlay">
            <i class="ti-eye"></i>
            <span class="d-block text-primary">Full Preview</span>
          </a>
        </div>
        <div v-else-if="param.thumbnail && param.video">
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="uploader(value)"
            allowfullscreen
          ></b-embed>
        </div>
      </div>
    </slot>
    <slot name="previewFile" v-if="!noPreviewFile">
      <div v-show="value" class="filePreview">
        <!-- <p>{{ value }}</p> -->
        <a 
          target="_blank"
          v-if="
            (value || '').substring((value || '').lastIndexOf('.') + 1) == 'xls' || 
            (value || '').substring((value || '').lastIndexOf('.') + 1) == 'xlsx'
          " 
          :href="`https://view.officeapps.live.com/op/embed.aspx?src=${apiUrl}/uploader/` + value"
        >{{ value }}</a>
        <a v-else target="_blank" :href="`${apiUrl}/uploader/${value}`">{{ value }}</a>
        <!-- <a v-if="value.substring(value.lastIndexOf('.') + 1) == 'docx' || value.substring(value.lastIndexOf('.') + 1) == 'doc'" :href="'https://docs.google.com/gview?url=http://rundeglobe.com/api/public/uploader/' + value + '&embedded=true'">{{ value}}</a> -->
      </div>
    </slot>
    <slot name="btn" v-if="!readonly">
      <div href="javascript:;" :class="'upload_wrapper '+(btnClass||'')" @click="uploadClick">
        <div class="file-upload-box">
          <label class="file-upload__label">
            <div class="wrap_text">
              <slot 
                v-if="!uploading" 
                name="label" 
                :uploadText="uploadText">
                  <i class="fas fa-image"></i> 
                  <p>{{uploadText}} {{label||'File'}}</p>
              </slot>
              <span v-if="uploading">
                <i class="fas fa-image"></i> <p>Uploading...</p>
              </span>
            </div>
          </label>
        </div>
      </div>
    </slot>
    <div v-if="!noLoading" class="ajax_progress" style=""> </div>
    <slot name="help">
      <div class="wrap_info" v-if="!noHelp&&!readonly">
        <p v-if="Object.prototype.hasOwnProperty.call(conf, 'imgsize_recommend')">
          Requirement Size ({{conf.imgsize_recommend}})
        </p>
        <p>
          Filename : {{value ? value.split('/')[1] : ''}}
        </p>
        <p>Format : <span class="text-uppercase">{{conf.rule_type}}</span></p>
        <p v-if="type === 'document'">Max file size {{$root.app.uploader["max_file_size"].bytesToSize()}}</p>
        <p v-else-if="type === 'file_race_guide'">Max file size {{$root.app.uploader["max_file_size"].bytesToSize()}}</p>
        <p v-else-if="type === 'video'">Max file size {{$root.app.uploader["max_video_size"].bytesToSize()}}</p>
        <p v-else>Max file size {{$root.app.uploader["max_image_size"].bytesToSize()}}</p>
      </div>
    </slot>
  </div>
</template>

<style>
.img-square-preview {
  width: 100%;
  padding-top: 50%;
  background-size: cover;
  background-position: center;
  position: relative;
}
.img-square-preview__overlay {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.85);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.25s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img-square-preview__overlay i {
  font-size: 16px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  text-align: center;
  color: white;
  background-color: #0145DC;
  display: inline-block;
  margin-bottom: 8px;
}
.img-square-preview__overlay span {
  font-weight: 500;
  font-size: 16px;
}
.img-square-preview:hover .img-square-preview__overlay {
  z-index: 9;
  opacity: 1;
}
</style>